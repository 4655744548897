import { Grid, Typography } from '@mui/material';

import { Card } from '@ecp/components';
import { getLineOfBusiness } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import {
  IconUICheckCircleSolid,
  LogoHomesite,
  useIsDesktop,
  useIsMobile,
  useIsTablet,
} from '@ecp/themes/base';

import { useStyles } from './Hero.styles';
import { HeroAccordion } from './HeroAccordion';
import metadata from './metadata';

export const Hero: React.FC = () => {
  const { classes, cx } = useStyles();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  const lineOfBusiness = useSelector(getLineOfBusiness);

  const heroTablet = (
    <>
      <Grid item className={classes.cardContentContainer}>
        {metadata[lineOfBusiness]?.benefits.map((heroItem) => (
          <Grid item>
            <h3 className={classes.benefitItem}>{heroItem.title}</h3>
            <p>{heroItem.description}</p>
          </Grid>
        ))}
      </Grid>
      <Grid>
        <Typography variant='body2Italics' className={classes.coverageNote}>
          Coverages are subject to the terms of your policy and applicable limits.
        </Typography>
      </Grid>
    </>
  );

  const heroDesktop = (
    <>
      <Grid container rowSpacing='25px' marginBottom='20px'>
        {metadata[lineOfBusiness]?.benefits.map((benefitItem, index) => (
          <Grid key={index} item>
            <h3 className={classes.benefitItem}>
              <IconUICheckCircleSolid className={classes.checkIcon} />
              {benefitItem.title}
            </h3>
            <p>{benefitItem.description}</p>
          </Grid>
        ))}
      </Grid>
      <p className={classes.benefitsCardLegalDisclaimer}>
        Coverages are subject to the terms of your policy and applicable limits.
      </p>
    </>
  );

  const body = isMobile ? <HeroAccordion /> : isTablet ? heroTablet : heroDesktop;

  const heroContainerClass = lineOfBusiness?.substring(
    lineOfBusiness.indexOf('.') + 1,
  ) as keyof typeof classes;

  return (
    <Grid container className={cx(classes.background, classes[heroContainerClass])}>
      {!isDesktop && (
        <h1 className={classes.heroHeaderMobileTitle}>{metadata[lineOfBusiness]?.pageTitle}</h1>
      )}
      <Card
        classes={{
          root: classes.benefitsCardRoot,
          content: classes.benefitsCardContent,
          footer: classes.benefitsFooter,
        }}
        body={body}
        footer={
          <>
            <LogoHomesite className={classes.logo} preserveAspectRatio='xMinYMin' />
            <p className={classes.footerText}>
              Insurance by Homesite which is trusted by over 2,500,000 policy holders
            </p>
          </>
        }
      />
    </Grid>
  );
};
