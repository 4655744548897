import baseMetadata from './metadata.js';
import type { Metadata } from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  // show global loading either for chase email or bridging from widget
  showGlobalLoadingDuringBlobRetrieve: true,
};

export default metadata;
