import type { Metadata } from './metadata';
import { adjustPolicyEffectiveStartDate } from './util/policyEffectiveDateAdjustor';

const metadata: Metadata = {
  policyEffectiveStartDateAdjustors: {
    renters: adjustPolicyEffectiveStartDate,
  },
  shouldGetPolicyEffectiveDateQueryParam: true,
};

export default metadata;
