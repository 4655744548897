import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AppHeader' })((theme) => ({
  root: {
    ...theme.mixins.headerRoot,
    height: 80,
    [theme.breakpoints.down('md')]: {
      height: 60,
    },
  },
  partnerLogoImage: {
    height: 28,
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      height: 20,
    },
  },
}));
