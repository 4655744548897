import { Grid } from '@mui/material';

import { LogoSpinnerWrapper } from '@ecp/components';
import { Page } from '@ecp/features/sales/shared/components';
import { useIsDesktop } from '@ecp/themes/base';

import { Hero } from './Hero';
import { useStyles } from './LandingPage.styles.zillow';
import { useLandingPage } from './util';

export const LandingPage: React.FC = () => {
  const { classes } = useStyles();
  const isDesktop = useIsDesktop();
  const { isLoading, isRetrieve, quoteFormComponent, retrieveFormComponent } = useLandingPage();

  if (isLoading) return <LogoSpinnerWrapper />;

  return (
    <Page analyticsElement='choice.landingPage.page'>
      <Grid
        container
        xs={12}
        rowSpacing={isDesktop || isRetrieve ? '40px' : '0px'}
        columnSpacing={!isRetrieve && isDesktop ? '40px' : '0px'}
        className={classes.root}
        justifyContent='center'
      >
        {!isRetrieve && (
          <Grid item xs={12} lg={6} order={{ lg: 2, xs: 1 }}>
            <Hero />
          </Grid>
        )}
        <Grid
          item
          lg={isRetrieve ? 7 : 6}
          xs={12}
          order={{ lg: 1, xs: 2 }}
          className={classes.landingWrapper}
        >
          <Grid item xs={12} lg={12}>
            {isRetrieve ? retrieveFormComponent : quoteFormComponent}
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
