import { memo } from 'react';

import { AppBar } from '@mui/material';

import { LogoPrimaryImageUrl } from '@ecp/themes/base';

import { useStyles } from './AppHeader.zillow.styles';

export const AppHeader: React.FC = memo(() => {
  const { classes } = useStyles();

  return (
    <AppBar className={classes.root} position='static'>
      <img
        src={LogoPrimaryImageUrl}
        alt='insurance agency logo'
        className={classes.partnerLogoImage}
      />
    </AppBar>
  );
});
