import { LineOfBusiness } from '@ecp/features/shared/product';
import type { StateOption } from '@ecp/types';

import type { BaseMetadata } from './metadata';

interface StateMetadata extends StateOption<BaseMetadata>, BaseMetadata {}

export const metadata: Partial<Record<LineOfBusiness, StateMetadata>> = {
  [LineOfBusiness.RENTERS]: {
    displayText: (
      <>
        Unfortunately, Renters insurance is not yet available in your state. We are rapidly
        expanding, check back soon for availability.
      </>
    ),
    stateOptions: {},
  },
  [LineOfBusiness.HOME]: {
    displayText: (
      <>
        Unfortunately, Home insurance is not yet available in your state. We are rapidly expanding,
        check back soon for availability.
      </>
    ),
    stateOptions: {
      AK: { displayText: <>Unfortunately, Home insurance is not yet available in your state.</> },
      AR: { displayText: <>Unfortunately, Home insurance is not yet available in your state.</> },
      DC: { displayText: <>Unfortunately, Home insurance is not yet available in your state.</> },
      FL: { displayText: <>Unfortunately, Home insurance is not yet available in your state.</> },
      HI: { displayText: <>Unfortunately, Home insurance is not yet available in your state.</> },
    },
  },
};

export default metadata;
