import { keyframes, useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './GlobalLoading.styles.js';

const loadingSteps = keyframes({
  '0%': {
    right: '100%',
  },
  '25%': {
    right: '66%',
  },
  '55%': {
    right: '33%',
  },
  '100%': {
    right: '0%',
  },
});

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        ...styleOverrides?.props?.classes,
        titleHeader: css({
          color: theme.palette.text.primary,
          textAlign: 'center',
          alignSelf: 'center',
        }),
        loadingIndicatorWrap: css({
          '&:after': {
            animation: `${loadingSteps} 17.5s linear forwards`,
          },
        }),
      },
    },
  });
};
