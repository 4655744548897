import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AppFooter' })((...[theme]) => ({
  footImageContainer: {
    marginTop: 60,
    width: '100%',
  },
  footImage: {
    width: '100%',
    marginBottom: -5,
  },
}));
