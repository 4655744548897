import type { SyntheticEvent } from 'react';
import { useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import { getLineOfBusiness } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconUICheckCircleSolid, IconUIExpandMore } from '@ecp/themes/base';

import { useStyles } from './Hero.styles';
import metadata from './metadata';

interface HeroAccordionItemsProps {
  title: string;
  description: string;
  expanded: boolean;
  onChange: (event: SyntheticEvent, isExpanded: boolean) => void;
}

const HeroAccordionItems: React.FC<HeroAccordionItemsProps> = (props) => {
  const { title, description, expanded, onChange } = props;
  const { classes } = useStyles();

  return (
    <Accordion classes={{ root: classes.accordion }} onChange={onChange} expanded={expanded}>
      <AccordionSummary
        expandIcon={<IconUIExpandMore />}
        classes={{ root: classes.accordionHeader }}
      >
        <IconUICheckCircleSolid className={classes.checkIcon} />
        <Typography variant='h2' className={classes.accordionHeaderText}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetails }}>
        <p>{description}</p>
        <Typography variant='body2Italics' className={classes.coverageNote}>
          Coverages are subject to the terms of your policy and applicable limits.
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export const HeroAccordion: React.FC = () => {
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (item: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? item : false);
  };

  return (
    <>
      {metadata[lineOfBusiness]?.benefits.map((heroItem, i) => (
        <HeroAccordionItems
          title={heroItem.title}
          description={heroItem.description}
          key={i}
          onChange={handleChange(`item${i}`)}
          expanded={expanded === `item${i}`}
        />
      ))}
    </>
  );
};
