import { Grid } from '@mui/material';

import { PRIMARY_INSURED_PERSON_LOCK } from '@ecp/features/sales/shared/constants';
import {
  DateOfBirthQuestion,
  EmailQuestion,
  FirstNameQuestion,
  LastNameQuestion,
  PhoneNumberQuestion,
} from '@ecp/features/sales/shared/questions';
import { useField, usePniRef } from '@ecp/features/sales/shared/store';

import { useStyles } from './PersonQuestions.styles';

export const PersonQuestions: React.FC = () => {
  const { classes } = useStyles();
  const pniRef = usePniRef();
  const primaryInsuredPersonLock = useField(PRIMARY_INSURED_PERSON_LOCK);
  const isLocked = Boolean(primaryInsuredPersonLock.props.value);

  return (
    <>
      <Grid item xs={12}>
        <p className={classes.groupLabel}>What's your name?</p>
        <Grid container rowSpacing='20px'>
          <Grid item xs={12} md={6} className={classes.columnLeft}>
            <FirstNameQuestion personRef={pniRef} disabled={isLocked} />
          </Grid>
          <Grid item xs={12} md={6} className={classes.columnRight}>
            <LastNameQuestion personRef={pniRef} disabled={isLocked} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <p className={classes.groupLabel}>What's your contact info?</p>
        <Grid container rowSpacing='20px'>
          <Grid item xs={12} md={6} className={classes.columnLeft}>
            <EmailQuestion personRef={pniRef} disabled={isLocked} />
          </Grid>
          <Grid item xs={12} md={6} className={classes.columnRight}>
            <PhoneNumberQuestion personRef={pniRef} disabled={isLocked} label='Phone (optional)' />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <p className={classes.groupLabel}>When were you born?</p>
        <Grid container rowSpacing='20px'>
          <Grid item xs={12} md={6} className={classes.columnLeft}>
            <DateOfBirthQuestion personRef={pniRef} disabled={isLocked} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
