import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './PageHeader.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        banner: css({
          // Increase specificity with `&&` otherwise media queries in the root class take precedence
          '&&': {
            backgroundColor: theme.palette.other.background,
            [theme.breakpoints.down('md')]: {
              padding: '30px 25px',
            },
            [theme.breakpoints.down('sm')]: {
              padding: '25px 15px',
            },
          },
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
