import { lazy } from 'react';

import type { RouteProps } from '@ecp/utils/routing';
import { Redirect, Route } from '@ecp/utils/routing';

import { PagePath } from '@ecp/features/sales/shared/routing';

import { LandingPage } from '../../views/LandingPage';

const SelectProductPage = lazy(() =>
  import('../../views/SelectProductPage').then((module) => ({ default: module.SelectProductPage })),
);
const CheckoutErrorPage = lazy(() =>
  import('@ecp/features/sales/checkout').then((module) => ({ default: module.CheckoutErrorPage })),
);
const CheckoutPage = lazy(() =>
  import('@ecp/features/sales/checkout').then((module) => ({ default: module.CheckoutPage })),
);
const CheckoutRetryPage = lazy(() =>
  import('@ecp/features/sales/checkout').then((module) => ({ default: module.CheckoutRetryPage })),
);
const PostBindPage = lazy(() =>
  import('@ecp/features/sales/checkout').then((module) => ({ default: module.PostBindPage })),
);
const HomeBasicPage = lazy(() =>
  import('@ecp/features/sales/quotes/property/home').then((module) => ({
    default: module.HomeBasicPage,
  })),
);
const HomeExteriorPage = lazy(() =>
  import('@ecp/features/sales/quotes/property/home').then((module) => ({
    default: module.HomeExteriorPage,
  })),
);
const HomeInteriorPage = lazy(() =>
  import('@ecp/features/sales/quotes/property/home').then((module) => ({
    default: module.HomeInteriorPage,
  })),
);
const HomeMarketValuePage = lazy(() =>
  import('@ecp/features/sales/quotes/property/home').then((module) => ({
    default: module.HomeMarketValuePage,
  })),
);
const QuotesPage = lazy(() =>
  import('@ecp/features/sales/quotes/property/shared').then((module) => ({
    default: module.QuotesPage,
  })),
);
const DiscountsPage = lazy(() =>
  import('@ecp/features/sales/shell').then((module) => ({
    default: module.DiscountsPage,
  })),
);
const ErrorNotFoundPage = lazy(() =>
  import('@ecp/features/sales/shell').then((module) => ({
    default: module.ErrorNotFoundPage,
  })),
);
const RatingCriteriaPage = lazy(() =>
  import('@ecp/features/sales/shell').then((module) => ({
    default: module.RatingCriteriaPage,
  })),
);
const SecondaryNamedInsuredPage = lazy(() =>
  import('@ecp/features/sales/shell').then((module) => ({
    default: module.SecondaryNamedInsuredPage,
  })),
);
const CoveragesPage = lazy(() =>
  import('../../views').then((module) => ({
    default: module.CoveragesPage,
  })),
);
const PersonAddressPage = lazy(() =>
  import('../../views').then((module) => ({
    default: module.PersonAddressPage,
  })),
);

export const dynamicRoutes = [
  // Quick quote flow pages
  <Route path={PagePath.LANDING} component={LandingPage} />,
  <Route path={PagePath.SELECT_PRODUCT} component={SelectProductPage} />,
  <Route path={PagePath.PERSON_ADDRESS} component={PersonAddressPage} />,
  <Route path={PagePath.QUOTES} component={QuotesPage} />,
  // App pages
  <Route path={PagePath.COVERAGES} component={CoveragesPage} />,
  <Route path={PagePath.CHECKOUT_RETRY} component={CheckoutRetryPage} />,
  <Route path={PagePath.CHECKOUT_ERROR} component={CheckoutErrorPage} />,
  <Route path={PagePath.CHECKOUT} component={CheckoutPage as RouteProps['component']} />,
  <Route path={PagePath.POST_BIND} component={PostBindPage as RouteProps['component']} />,
  <Route path={PagePath.RATING_CRITERIA} component={RatingCriteriaPage} />,
  <Route path={PagePath.HOME_BASIC} component={HomeBasicPage} />,
  <Route path={PagePath.HOME_EXTERIOR} component={HomeExteriorPage} />,
  <Route path={PagePath.HOME_INTERIOR} component={HomeInteriorPage} />,
  <Route path={PagePath.HOME_MARKET_VALUE} component={HomeMarketValuePage} />,
  <Route path={PagePath.HOME_DISCOUNTS} component={DiscountsPage} />,
  <Route path={PagePath.SECONDARY_NAMED_INSURED} component={SecondaryNamedInsuredPage} />,
  <Route path={PagePath.DEFAULT}>
    <Redirect to={PagePath.LANDING} replace />
  </Route>,
  // Fallback page
  <Route component={ErrorNotFoundPage} />,
];
