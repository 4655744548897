import { FooterRedesign } from '@ecp/features/sales/shared/components';
import { FooterImageImageUrl } from '@ecp/themes/partners/zillow';

import { useStyles } from './AppFooter.styles.zillow';

export interface AppFooterProps {
  savingsTextElement?: React.ReactElement<React.PropsWithChildren>;
  showFAQLink?: boolean;
  showAccessibilityLink?: boolean;
}

export const AppFooter: React.FC<AppFooterProps> = (props) => {
  const { savingsTextElement = null, showFAQLink = false, showAccessibilityLink = false } = props;
  const { classes } = useStyles();

  return (
    <FooterRedesign
      savingsTextElement={savingsTextElement}
      showFAQLink={showFAQLink}
      showAccessibilityLink={showAccessibilityLink}
      elementBelowFooter={
        <div className={classes.footImageContainer}>
          <img className={classes.footImage} alt='footer Img' src={FooterImageImageUrl} />
        </div>
      }
      copyrightTextElement={
        <>© {new Date().getFullYear()} Homesite Group, Inc. All Rights Reserved.</>
      }
      disclaimerTextElement={
        <>
          Disclaimer: Property insurance is offered through Zillow Insurance Services, LLC (“ZIS”),
          a licensed insurance producer. Insurance policies are underwritten and issued by member
          companies and affiliates of Homesite Group Incorporated, which is unaffiliated with ZIS.
          Prices, coverages, features, and discounts vary among these insurers. Refer to
          Participating Insurers link for a list of companies that underwrite and issue insurance
          products available through this site. ZIS assumes no responsibility for any claims and
          makes no representations regarding the terms and conditions of any policies issued by the
          insurers. ZIS is compensated by Homesite in connection with the sale of insurance policies
          through ZIS.
        </>
      }
    />
  );
};
