import { merge } from '@ecp/utils/common';

import type { PaletteOverrides } from '@ecp/themes/base';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { palette as basePalette } from '../../../../base/src';

const paletteOverrides: PaletteOverrides = {
  actions: {
    disabledBg: '#e6e6e9',
    disabledText: '#75757a',
  },
  error: {
    contrastText: '#ffffff',
    dark: '#7d2c27',
    darkText: '#2a2a33',
    light: '#f1dbd9',
    main: '#b33f37',
  },
  grey: {
    '50': '#ffffff',
    '100': '#f8f8f9',
    '200': '#f1f1f4',
    '300': '#e6e6e9',
    '400': '#dddde1',
    '500': '#d1d1d5',
    '600': '#a7a6ab',
    '700': '#7e7e82',
    '800': '#54545a',
    '900': '#2a2a33',
  },
  icon: {
    default: '#ffffff',
    hover: '#006aff',
    stroke: '#54545a',
  },
  info: {
    contrastText: '#ffffff',
    dark: '#0d4599',
    darkText: '#2a2a33',
    light: '#f2faff',
    main: '#006aff',
  },
  other: {
    backdrop: '#2a2a3366',
    background: '#596b82',
    backgroundContrastText: '#ffffff',
    body: '#ffffff',
    border: '#d1d1d5',
    caution: '#a06603',
    cookieBanner: '#ffffff',
    divider: '#d1d1d5',
    snackbar: '#2a2a33',
    tooltip: '#2a2a33',
  },
  partner: {
    footerBackground: '#ffffff',
    footerTextLink: '#0d4599',
    footerTextPrimary: '#2a2a33',
    footerTextSecondary: '#54545a',
    headerBackground: '#ffffff',
  },
  primary: {
    background: '#f2faff',
    border: '#006aff',
    contrastText: '#ffffff',
    dark: '#0d4599',
    darkText: '#006aff',
    light: '#f2faff',
    main: '#006aff',
  },
  secondary: {
    background: '#f2faff',
    border: '#006aff',
    contrastText: '#ffffff',
    dark: '#0d4599',
    darkText: '#006aff',
    light: '#f2faff',
    main: '#006aff',
  },
  shadow: {
    primary: '#2a2a3333',
  },
  success: {
    complete: '#ffffff',
    contrastText: '#ffffff',
    dark: '#0d611d',
    darkText: '#2a2a33',
    light: '#d2e9d6',
    main: '#128a29',
  },
  text: {
    disabled: '#75757a',
    hover: '#001751',
    link: '#0d4599',
    primary: '#2a2a33',
    secondary: '#54545a',
    tertiary: '#75757a',
  },
  warning: {
    contrastText: '#ffffff',
    dark: '#923b0f',
    darkText: '#2a2a33',
    light: '#f6dfd3',
    main: '#d05416',
  },
};

export const palette = merge({}, basePalette, paletteOverrides);
