import { LineOfBusiness } from '@ecp/features/shared/product';

type Metadata = Partial<
  Record<
    LineOfBusiness,
    {
      benefits: Array<{ title: string; description: string }>;
      pageTitle: string;
    }
  >
>;

const metadata: Metadata = {
  [LineOfBusiness.HOME]: {
    benefits: [
      {
        title: 'Helps pay to repair damage',
        description:
          'Covers expenses and repairs, up to your limit, when your home is damaged or suffers a loss due to things like fire, theft, and most weather-related events, such as wind and hail.',
      },
      {
        title: 'Covers your personal belongings',
        description:
          'From clothing to furniture, homeowners protection extends to most of the stuff that fills your house and makes it a home.',
      },
      {
        title: 'Protects you financially from lawsuits and injury',
        description:
          'Helps pay for medical bills and can save you from owing a substantial amount of money if you are sued because someone is injured or their property is damaged while on your property.',
      },
    ],
    pageTitle: "Let's start building your home policy",
  },
  [LineOfBusiness.RENTERS]: {
    benefits: [
      {
        title: 'Covers furniture, electronics & more',
        description:
          'From clothing to kitchenware, get protection for your stuff whether it’s inside your home, on the floor of your car, or strapped to your back on a hike.',
      },
      {
        title: 'Protects you financially from injury',
        description:
          'If someone’s hurt while in your home, regardless of fault, your policy could help pay the medical expenses and assist with legal expenses.',
      },
      {
        title: 'Assists with temporary living costs',
        description:
          'Should your home become unliveable, we’ll help cover the additional cost of any temporary living arrangements you are forced to make. ',
      },
    ],
    pageTitle: "If it's worth moving, it's worth protecting.",
  },
};

export default metadata;
