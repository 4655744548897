import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { navigate } from '@ecp/utils/routing';

import { GridItem } from '@ecp/components';
import { AgreementContent } from '@ecp/features/sales/shared/components';
import { PagePath } from '@ecp/features/sales/shared/routing';
import { getLineOfBusiness } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { useIsDesktop } from '@ecp/themes/base';

import metadata from '../Hero/metadata';
import { Main } from '../Main';
import { useStyles } from './GetQuote.styles.zillow';

export const GetQuote: React.FC = () => {
  const { classes } = useStyles();
  const isDesktop = useIsDesktop();
  const lineOfBusiness = useSelector(getLineOfBusiness);

  const navigateToPrivacyPolicy = useCallback((): void => {
    trackClick({ action: 'BodyTextPrivacyPolicyLink', label: 'PrivacyPolicy' });
    navigate(PagePath.PRIVACY_POLICY, { external: true });
  }, []);

  const navigateToUseOfCreditDisclosure = useCallback((): void => {
    trackClick({ action: 'HowWeUseInsuranceScoresLink', label: 'HowWeUseInsuranceScores' });
    navigate(PagePath.USE_OF_CREDIT_DISCLOSURE, { external: true });
  }, []);

  return (
    <Grid container rowSpacing='40px'>
      {isDesktop && (
        <Grid item xs={10} className={classes.desktopHeading}>
          <h1>{metadata[lineOfBusiness]?.pageTitle}</h1>
        </Grid>
      )}
      <Main />

      <div className={classes.agreementPadding}>
        <GridItem xs={12}>
          <AgreementContent
            onNavigateToPrivacyPolicy={navigateToPrivacyPolicy}
            onNavigateToUseOfCreditDisclosure={navigateToUseOfCreditDisclosure}
          />
        </GridItem>
      </div>
    </Grid>
  );
};
