import type { Metadata } from './metadata.js';
import baseMetadata from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  step3: (
    <div className='step step3'>
      <p className='stepHeader'>Preparing your quote</p>
      <p>Building your Homesite quote</p>
    </div>
  ),
};
export default metadata;
