import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AddressQuestions' })((theme) => ({
  root: {},
  groupLabel: theme.typography.body3Bold,
  content: theme.typography.body4,
  expandButton: {
    ...theme.typography.body4Underline,
    verticalAlign: 'baseline',
  },
}));
