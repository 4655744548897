import { Link } from '../Link';
import type { AgreementContentProps } from './AgreementContent.js';
import { useStyles } from './AgreementContent.styles';

export const AgreementContent: React.FC<AgreementContentProps> = ({
  className,
  onNavigateToPrivacyPolicy,
  onNavigateToUseOfCreditDisclosure,
}) => {
  const { classes } = useStyles();

  return (
    <div className={className}>
      <p className={classes.agreementContent}>
        <strong>By clicking "Get quote", I agree to the following:</strong>
        <br />
        To provide you with an accurate premium, information from consumer reporting agencies will
        be used. Please review your rights under the FCRA and Homesite's{' '}
        <Link component='button' onClick={onNavigateToPrivacyPolicy}>
          privacy policy
        </Link>
        . Learn more about how Homesite uses insurance-based credit information{' '}
        <Link component='button' onClick={onNavigateToUseOfCreditDisclosure}>
          here
        </Link>
        .
      </p>
    </div>
  );
};
