import baseMetadata from './metadata.js';
import type { Metadata } from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  displayNewQuoteButton: true,
  getQuoteButtonText: 'Get quote',
  shouldSetPersonLocks: true,
  shouldCallPrefill: true,
};

export default metadata;
