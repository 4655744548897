import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'LandingPage' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    ...theme.mixins.pageRoot,
    margin: 0,
    padding: '0px 30px 0px 0px',
    [theme.breakpoints.down('lg')]: {
      padding: 0,
      marginLeft: 0,
    },
  },
  landingWrapper: {
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '16px !important',
      paddingRight: '16px !important',
    },
  },
  dialogRoot: {
    maxWidth: 750,
  },
  spinner: {
    color: theme.palette.primary.main,
  },
  spinnerContainer: {
    width: 40,
    margin: 'auto',
    marginTop: 10,
  },
}));
