import { makeStyles } from '@ecp/themes/base';
import {
  LandingPageHome1024ImageUrl,
  LandingPageHome1366ImageUrl,
  LandingPageHome375ImageUrl,
  LandingPageHome768ImageUrl,
  LandingPageRenters1024ImageUrl,
  LandingPageRenters1366ImageUrl,
  LandingPageRenters375ImageUrl,
  LandingPageRenters768ImageUrl,
} from '@ecp/themes/partners/zillow';

export const useStyles = makeStyles({ name: 'Hero' })((theme) => ({
  background: {
    alignContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
  },
  RENTERS: {
    [theme.breakpoints.up('xl')]: {
      backgroundImage: `url(${LandingPageRenters1366ImageUrl})`,
      maxHeight: 895,
      borderRadius: 20,
    },
    [theme.breakpoints.down('xl')]: {
      backgroundImage: `url(${LandingPageRenters1024ImageUrl})`,
    },
    [theme.breakpoints.down('lg')]: {
      backgroundImage: `url(${LandingPageRenters768ImageUrl})`,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${LandingPageRenters375ImageUrl})`,
    },
  },
  HOME: {
    [theme.breakpoints.up('xl')]: {
      backgroundImage: `url(${LandingPageHome1366ImageUrl})`,
      maxHeight: 895,
      borderRadius: 20,
    },
    [theme.breakpoints.down('xl')]: {
      backgroundImage: `url(${LandingPageHome1024ImageUrl})`,
    },
    [theme.breakpoints.down('lg')]: {
      backgroundImage: `url(${LandingPageHome768ImageUrl})`,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${LandingPageHome375ImageUrl})`,
    },
  },
  AUTO: {},
  BUNDLE: {},
  BUNDLE_AUTO_RENTERS: {},
  benefitsCardRoot: {
    ...theme.mixins.shadow2,
    borderRadius: 20,
    height: 'auto',
    margin: '50px -50px 40px auto',
    [theme.breakpoints.down('xl')]: {
      margin: '50px -20px 50px 50px',
    },
    [theme.breakpoints.down('lg')]: {
      margin: '0px 16px 40px',
    },
  },
  benefitsCardContent: {
    margin: 0,
    padding: '40px 30px 20px',
    [theme.breakpoints.down('lg')]: {
      padding: '15px 30px 20px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '15px 15px 0px',
    },
  },
  benefitsCardLegalDisclaimer: theme.typography.body2Italics,
  benefitsFooter: {
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 30px',
    [theme.breakpoints.down('md')]: {
      padding: 15,
    },
  },
  logo: {
    height: 17,
    width: '100%',
    maxWidth: 93,
    [theme.breakpoints.down('md')]: {
      maxWidth: 75,
    },
  },
  benefitItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    [theme.breakpoints.down('lg')]: {
      minHeight: 70,
      alignItems: 'start',
    },
  },
  checkIcon: {
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
    height: 40,
    width: 40,
    margin: '-15px 0px -15px -10px',
    [theme.breakpoints.down('md')]: {
      margin: '-10px 0px -15px -10px',
    },
  },
  mobileCardContent: {
    padding: 0,
  },
  mobileCardDivider: {
    margin: 0,
  },
  heroCardMobile: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
    borderRadius: 20,
  },
  heroCardTablet: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
    borderRadius: 20,
    display: 'flex',
  },
  heroItemContainer: {
    margin: '10px 0',
  },
  footerLogo: {
    width: 190,
    height: 20,
    display: 'flex',
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      marginLeft: 20,
    },
    [theme.breakpoints.down('md')]: {
      width: 150,
    },
  },
  footerText: {
    ...theme.typography.body2,
    marginLeft: 10,
    [theme.breakpoints.down('md')]: {
      minWidth: 170,
      fontStyle: 'italic',
    },
  },
  coverageNote: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
      justifyContent: 'left',
    },
  },
  cardContentContainer: {
    margin: '15px 0',
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      gap: 30,
    },
  },
  accordion: {
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.other.border}`,
    borderRadius: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
    '&.MuiAccordion-root:last-of-type': {
      borderBottom: 0,
    },
  },
  accordionHeader: {
    minHeight: 40,
    '&.MuiAccordionSummary-root': {
      padding: 0,
    },
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionHeaderText: {
    ...theme.typography.body1,
    margin: '0',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
  },
  heroHeaderMobileTitle: {
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    padding: '45px 25px',
    margin: '0px auto',
    maxWidth: 500,
    [theme.breakpoints.down('md')]: {
      maxWidth: 400,
    },
  },
  accordionDetails: {
    padding: '8px 16px 16px 0px',
  },
}));
